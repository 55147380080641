import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Link, useIntl, FormattedMessage } from 'gatsby-plugin-intl';

import Button from '../../../components/Button/Button';
import Layout from '../../../components/Layouts/layout';
import SEO from '../../../components/seo';
import SubPageNav from '../../../components/SubPageNav/SubPageNav';
import SubPageBottomNav from '../../../components/SubPageBottomNav/SubPageBottomNav';

const BuilderAfterLicensingPage = () => {
	const intl = useIntl();
	const data = useStaticQuery(graphql`
		query {
			builderImg: file(relativePath: { eq: "mintoBuilder01.jpg" }) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid
					}
				}
			}

			changeReq: file(relativePath: { eq: "docs/VBdash/HCRA_ChangeRequestForm_2021_01_29_v03.pdf" }) {
				publicURL
			}
		}
	`);

	return (
		<Layout
			view='builder'
			breadcrumb={{
				text: <FormattedMessage id='builderNav.dashboard' />,
				path: '/builder-vendor/dashboard/'
			}}
			header={
				<>
					<FormattedMessage id='builderNav.becomeA' />{' '}
					<span style={{ fontWeight: `bold` }}>
						<FormattedMessage id='builderNav.becomeB' />
					</span>
				</>
			}
		>
			<SEO lang={intl.locale} title={intl.formatMessage({ id: 'builderNav.step5' })} />
			<Container>
				<Row>
					<Col>
						<SubPageNav
							links={[
								{
									text: `${intl.formatMessage({
										id: 'builderNav.step1'
									})}?`,
									path: '/builder-vendor/become-a-licensed-builder/do-i-need-a-licence'
								},
								{
									text: <FormattedMessage id='builderNav.step2' />,
									path: '/builder-vendor/become-a-licensed-builder/before-you-apply'
								},
								{
									text: <FormattedMessage id='builderNav.step3' />,
									path: '/builder-vendor/become-a-licensed-builder/applying-for-a-licence'
								},
								{
									text: <FormattedMessage id='builderNav.step4' />,
									path: '/builder-vendor/become-a-licensed-builder/after-your-application-is-submitted/'
								},
								{
									text: <FormattedMessage id='builderNav.step5' />,
									path: '/builder-vendor/become-a-licensed-builder/after-licensing',
									active: true
								}
							]}
						/>
					</Col>
				</Row>

				<Row className='pageRow'>
					<Col>
						<h2>
							<FormattedMessage id='becomeBuilder5.headerA' />
						</h2>
						<p>
							<FormattedMessage id='becomeBuilder5.contentA' />
						</p>

						<h2>
							<FormattedMessage id='becomeBuilder5.headerB' />
						</h2>
						<p>
							<FormattedMessage id='becomeBuilder5.contentBi' />
						</p>
						<ul>
							<li>
								<FormattedMessage id='becomeBuilder5.list01A' />
							</li>
							<li>
								<FormattedMessage id='becomeBuilder5.list01B' />
							</li>
							<li>
								<FormattedMessage id='becomeBuilder5.list01C' />
							</li>
						</ul>
						<p>
							<FormattedMessage id='becomeBuilder5.contentBii' />{' '}
							<a href='info@hcraontario.ca'>info@hcraontario.ca</a>.{' '}
							<FormattedMessage id='becomeBuilder5.contentBiii' />
						</p>
					</Col>
				</Row>

				<Row className='pageRow'>
					<Col lg={12} md={12}>
						<h2>
							<FormattedMessage id='becomeBuilder5.headerC' />{' '}
						</h2>
						<p>
							<FormattedMessage id='becomeBuilder5.contentCi' />
						</p>
						<p>
							<FormattedMessage id='becomeBuilder5.contentCii' />{' '}
							<FormattedMessage id='becomeBuilder5.contentCiii' />
							<FormattedMessage id='becomeBuilder5.contentCiv' />{' '}
							<FormattedMessage id='becomeBuilder5.contentCv' />{' '}
							<FormattedMessage id='becomeBuilder5.contentCvi' />{' '}
							<a href='mailto:info@hcraontario.ca'>info@hcraontario.ca</a>.
						</p>

						<Button
							linkExternal
							linkPath='https://builderportal.hcraontario.ca'
							text={intl.formatMessage({
								id: 'becomeBuilder5.contentCiii'
							})}
						/>
					</Col>
				</Row>

				<Row className='pageRow'>
					<Col lg={4} md={12}>
						<Img fluid={data.builderImg.childImageSharp.fluid} alt='' />
					</Col>

					<Col lg={8} md={12}>
						<ol className='largeNums'>
							<li>
								<div>
									<b>
										<FormattedMessage id='becomeBuilder5.list02A_B' />
									</b>
									<br />
									<FormattedMessage id='becomeBuilder5.list02A' />
								</div>
							</li>

							<li>
								<div>
									<b>
										<FormattedMessage id='becomeBuilder5.list02B_B' />
									</b>
									<br />
									<FormattedMessage id='becomeBuilder5.list02B' />
									<ul>
										<li>
											<FormattedMessage id='becomeBuilder5.list02C' />
										</li>
										<li>
											<FormattedMessage id='becomeBuilder5.list02D' />
										</li>
									</ul>
								</div>
							</li>

							<li>
								<div>
									<b>
										<FormattedMessage id='becomeBuilder5.list02E_B' />
									</b>
									<br />
									<FormattedMessage id='becomeBuilder5.list02E' />
									<ul>
										<li>
											<FormattedMessage id='becomeBuilder5.list02F' />
										</li>
										<li>
											<FormattedMessage id='becomeBuilder5.list02G' />
										</li>
									</ul>
								</div>
							</li>

							<li>
								<div>
									<b>
										<FormattedMessage id='becomeBuilder5.list02H_B' />
									</b>
									<br />
									<FormattedMessage id='becomeBuilder5.list02H' />
								</div>
							</li>
						</ol>
					</Col>
				</Row>

				<Row className='pageRow'>
					<Col>
						<h2>
							<FormattedMessage id='becomeBuilder5.headerD' />
						</h2>
						<p>
							<FormattedMessage id='becomeBuilder5.contentDi' />{' '}
							<a
								aria-label='Tarion enrolment process'
								href='https://www.tarion.com/qualification-enrolment-process'
							>
								<FormattedMessage id='becomeBuilder5.contentDv' />
							</a>{' '}
							<FormattedMessage id='becomeBuilder5.contentDvi' />
						</p>
						<p>
							<FormattedMessage id='becomeBuilder5.contentDii' />{' '}
							<a href='https://www.tarion.com'>Tarion</a>{' '}
							<FormattedMessage id='becomeBuilder5.contentDiii' />
						</p>
						<p>
							<FormattedMessage id='becomeBuilder5.contentDiv' />
						</p>

						<h2>
							<FormattedMessage id='becomeBuilder5.headerE' />
						</h2>
						<p>
							<FormattedMessage id='becomeBuilder5.contentEi' />{' '}
							<strong>
								<FormattedMessage id='becomeBuilder5.price01' />
							</strong>{' '}
							<FormattedMessage id='becomeBuilder5.contentEii' />{' '}
							{intl.locale === 'fr' && (
								<b>
									<FormattedMessage id='becomeBuilder5.contentEiii' />
								</b>
							)}
						</p>
					</Col>
				</Row>

				<Row className='pageRow'>
					<Col>
						<h2>
							<FormattedMessage id='becomeBuilder5.headerF' />
						</h2>
						<p>
							<FormattedMessage id='becomeBuilder5.contentFi' />
						</p>
						<p>
							<FormattedMessage id='becomeBuilder5.contentFii' />{' '}
							<a href='../../../1. HCRA Advisory - Guide to Good Conduct v1.7 2021-07-09.pdf'>
								<FormattedMessage id='becomeBuilder5.contentFiii' />
							</a>
							. <FormattedMessage id='becomeBuilder5.contentFiv' />
						</p>

						<h3>
							<FormattedMessage id='becomeBuilder5.headerG' />
						</h3>
						<p>
							<FormattedMessage id='dacPage.contentAi' />
							<a
								href={intl.formatMessage({
									id: 'dacPage.linkA'
								})}
							>
								<FormattedMessage id='dacPage.contentAii' />
							</a>
							<FormattedMessage id='dacPage.contentAiii' />
						</p>
						<p>
							<FormattedMessage id='becomeBuilder5.contentGii' />{' '}
							<Link to='/about/discipline-and-appeals-committees/'>
								<FormattedMessage id='becomeBuilder5.contentGiv' />
							</Link>
							.
						</p>
						<p>
							<FormattedMessage id='becomeBuilder5.contentGiii' />
						</p>
					</Col>
				</Row>

				<Row className='pageRow'>
					<Col>
						<h2>
							<FormattedMessage id='becomeBuilder5.headerH' />
						</h2>
						<p>
							<FormattedMessage id='becomeBuilder5.contentH' />
						</p>
					</Col>
				</Row>
			</Container>

			<Container>
				<Row>
					<Col md={12}>
						<SubPageBottomNav
							prevLink={{
								text: <FormattedMessage id='builderNav.step4' />,
								path: '/builder-vendor/become-a-licensed-builder/after-your-application-is-submitted/'
							}}
						/>
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};

export default BuilderAfterLicensingPage;
